<template>
  <div class="">
    <div class="rounded-xl shadow-xl bg-white p-6 flex">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">预约用户</h1>
        <p class="mt-2 text-sm text-gray-700">
          所有通过小程序预约的用户信息都会在此列表中进行管理
        </p>
      </div>
    </div>
    <div class="rounded-xl shadow-xl bg-white p-6 flex mt-4">
      <el-form :inline="true">
        <el-form-item label="昵称">
          <el-input placeholder="昵称" v-model="searchForm.nickName"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input placeholder="手机号" v-model="searchForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            v-model="searchForm.date"
            type="date"
            format="yyyy-M-d"
            value-format="yyyy-M-d"
            :clearable="false"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="排序">
          <el-select v-model="searchForm.sort">
            <el-option key="appointment" value="appointment" label="按预约时间倒序"></el-option>
            <el-option key="createdTime" value="createdTime" label="按提交时间正序"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onFind">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="rounded-xl shadow-xl bg-white p-6 flex mt-4">
      <el-table :data="tableData" style="width: 100%; min-height: 300px" v-loading="loading">
        <el-table-column label="预约时间" width="180">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px"
              >{{ scope.row.year }}-{{ scope.row.month }}-{{
                scope.row.day
              }}</span
            >
            <span class="ml-2">{{ scope.row.hour }}点</span>
          </template>
        </el-table-column>
        <el-table-column label="昵称" width="180">
          <template slot-scope="scope">
            <!--                <el-tag size="medium" class="mx-2">{{ scope.row.account.nickName }}</el-tag>-->
            <el-tag size="medium" class="mx-2">
              {{ scope.row.account ? scope.row.account.nickName : "未设置" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="邮箱" width="180">
          <template slot-scope="scope">
            {{ scope.row.account ? scope.row.account.email : "未设置" }}
          </template>
        </el-table-column>
        <el-table-column label="电话" width="180">
          <template slot-scope="scope">
            {{ scope.row.account ? scope.row.account.mobile : "未设置" }}
          </template>
        </el-table-column>
        <el-table-column label="提交时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.created_time }}
          </template>
        </el-table-column>
        <!--        <el-table-column label="描述" width="180">-->
        <!--          <template slot-scope="scope">-->
        <!--            <p>{{ scope.row.desc }}</p>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleCancel(scope.row)"
              >取消
            </el-button>
            <!--            <el-button size="mini" type="danger">删除 </el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mt-4 flex flex-col">
      <el-pagination
        background
        :page-count="page.pageIndex"
        :page-size="page.pageSize"
        layout="prev, pager, next"
        :total="page.pageTotal"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script type="text/javascript">
import dayjs from "dayjs";
export default {
  data() {
    return {
      loading: false,
      page: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      searchForm: {
        nickName: "",
        mobile: "",
        name: "",
        year: "",
        month: "",
        day: "",
        date: "", // dayjs().format("YYYY-MM-DD")
        sort: "appointment", // dayjs().format("YYYY-MM-DD")
      },
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {
    // 查询
    onFind() {
      this.page.pageIndex = 1;
      this.getList();
    },

    // 查询表格数据
    getList() {
      this.loading = true;
      console.log("this.searchForm.date::", this.searchForm.date);
      let times = "";
      if (this.searchForm.date) {
        times = this.searchForm.date.split("-");
        this.searchForm.year = times[0];
        this.searchForm.month = times[1];
        this.searchForm.day = times[2];
      }

      this.$cloud
        .get("appointment/list", { ...this.page, ...this.searchForm })
        .then((res) => {
          this.loading= false
          if (res) {
            this.page.pageTotal = res.total;
            this.tableData = res.list;
          }
        })
        .catch((err) => {
          this.loading= false
          this.$message.error(err);
        });
    },

    // 取消预约
    handleCancel(row) {
      this.$confirm(`确定取消本次预约吗?`, "取消预约提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$cloud
          .post("appointment/cancel_by_id", {
            id: row.id,
          })
          .then(() => {
            this.loading = false;
            this.$message.success("取消预约成功！");
            this.getList();
          })
          .catch((err) => {
            console.log("err", err);
            this.$message.error(err.msg);
            this.loading = false;
          });
      });
    },
    changePage(e) {
      console.log("e", e);
      this.page.pageIndex = e;
      this.getList();
    },
  },
};
</script>
<style lang="scss"></style>
